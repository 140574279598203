import React, {ChangeEvent, useEffect} from "react";
import {
    Button,
    Checkbox,
    createMuiTheme,
    FormControlLabel,
    FormGroup,
    Grid,
    MuiThemeProvider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import {defaultStyles} from "../../components/list";
import {AppState, OrdersSearch} from "../../models";
import {AnyAction} from "redux";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {OrderListItem} from "../../api/models";
import apiClient, {BillinOrderState} from "../../api";
import clsx from "clsx";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useParams} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    progress: {
        position: "absolute",
        right: "40vw",
        bottom: "50vh"
    },
    noselect: {
        userSelect: "none"
    },
    disabled: {
        "@global": {
            "td": {
                color: "rgba(127, 127, 127, 0.5)"
            }
        }
    },
}));

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#2e8b57"
        },
        secondary: {
            main: "#f50057"
        }
    }
});

type SetOrderStateFn = (_: OrderListItem) => Promise<void>;

function stateButtons(
        order: OrderListItem,
        allowOrder: SetOrderStateFn,
        cancelOrder: SetOrderStateFn,
        suspendOrder: SetOrderStateFn) {

    if (order.subState === "FRAUD/SUSPENDED") {
        return (
            <MuiThemeProvider theme={theme}>
                <Button size="small" variant="contained" color="primary"
                        onClick={ async () => await allowOrder(order) }>
                    Разморозить
                </Button>
                &nbsp;&nbsp;
                <Button size="small" variant="contained" color="secondary"
                        onClick={ async () => await cancelOrder(order) }>
                    Отменить
                </Button>
            </MuiThemeProvider>
        );
    }

    if (order.subState === "FRAUD/SUSPICIOUS") {
        return (
            <MuiThemeProvider theme={theme}>
                <Button size="small" variant="contained" color="primary"
                        onClick={ async () => await allowOrder(order) }>
                    Разрешить
                </Button>
                &nbsp;&nbsp;
                <Button size="small" variant="contained" color="secondary"
                        onClick={ async () => await suspendOrder(order) }>
                    Заморозить
                </Button>
                &nbsp;&nbsp;
                <Button size="small" variant="contained" color="secondary"
                        onClick={ async () => await cancelOrder(order) }>
                    Отменить
                </Button>
            </MuiThemeProvider>
        );
    }

    return <div></div>;
}

export function ordersListAction(state: AppState, action: AnyAction) {
    if (action.type === "ORDERS_LIST/REPLACE_ITEMS") {
        const nextOrdersState: OrdersSearch = {
            ...state.orders,
            items: action.payload.items
        };

        return {
            ...state,
            ...{ orders: nextOrdersState }
        };
    }

    const currentOrdersState: OrdersSearch = state.orders;
    const nextOrdersState: OrdersSearch = { ...currentOrdersState, ...action.payload };

    if (action.payload.refine) {
        nextOrdersState.refine = {
            ...currentOrdersState.refine,
            ...action.payload.refine
        };
    }

    if (action.type === "ORDERS_LIST/CLEAR") {
        nextOrdersState.loadedAll = false;
        nextOrdersState.items = [];
    }
    else if (action.payload.items) {
        nextOrdersState.items = [
            ...(currentOrdersState.items ?? []),
            ...(nextOrdersState.items ?? [])
        ];
    }

    if (nextOrdersState.fetching) {
        if (nextOrdersState.loadedAll) {
            nextOrdersState.fetching = false;
        }
        else {
            const filter = nextOrdersState.refine.isSuspiciousOnly
                ? [ BillinOrderState.FraudScreeningSuspicious ]
                : [ BillinOrderState.FraudScreeningSuspicious, BillinOrderState.Completed ]

            apiClient
                .fetchOrderList(
                    nextOrdersState.refine.nextPageToken,
                    filter)
                .then(result => {
                    action.dispatch({
                        type: "ORDERS_LIST",
                        payload: {
                            fetching: false,
                            loadedAll: ((result.nextPageToken ?? "").length === 0),
                            items: result.items,
                            refine: {
                                nextPageToken: result.nextPageToken
                            }
                        },
                        dispatch: action.dispatch
                    });
                });
        }
    }

    return {
        ...state,
        ...{ orders: nextOrdersState }
    };
}

export function suspendedOrdersListAction(state: AppState, action: AnyAction) {
    if (action.type === "SUSPENDED/ORDERS_LIST/REPLACE_ITEMS") {
        const nextOrdersState: OrdersSearch = {
            ...state.suspendedOrders,
            items: action.payload.items
        };

        return {
            ...state,
            ...{ suspendedOrders: nextOrdersState }
        };
    }

    const currentOrdersState: OrdersSearch = state.suspendedOrders;
    const nextOrdersState: OrdersSearch = { ...currentOrdersState, ...action.payload };

    if (action.payload.refine) {
        nextOrdersState.refine = {
            ...currentOrdersState.refine,
            ...action.payload.refine
        };
    }

    if (action.type === "SUSPENDED/ORDERS_LIST/CLEAR") {
        nextOrdersState.loadedAll = false;
        nextOrdersState.items = [];
    }
    else if (action.payload.items) {
        nextOrdersState.items = [
            ...(currentOrdersState.items ?? []),
            ...(nextOrdersState.items ?? [])
        ];
    }

    if (nextOrdersState.fetching) {
        if (nextOrdersState.loadedAll) {
            nextOrdersState.fetching = false;
        }
        else {
            const filter = [ BillinOrderState.FraudScreeningSuspended ];

            apiClient
                .fetchOrderList(
                    nextOrdersState.refine.nextPageToken,
                    filter)
                .then(result => {
                    action.dispatch({
                        type: "SUSPENDED/ORDERS_LIST",
                        payload: {
                            fetching: false,
                            loadedAll: ((result.nextPageToken ?? "").length === 0),
                            items: result.items,
                            refine: {
                                nextPageToken: result.nextPageToken
                            }
                        },
                        dispatch: action.dispatch
                    });
                });
        }
    }

    return {
        ...state,
        ...{ suspendedOrders: nextOrdersState }
    };
}

export function suspendedOrdersListAddItem(state: AppState, action: AnyAction) {
    const item = action.payload.item as OrderListItem;

    const newItems = state.suspendedOrders.items ?? [];
    newItems.push(item);
    newItems.sort(
        (a, b) => (b.createdAt.getTime() - a.createdAt.getTime()));

    state.suspendedOrders.items = newItems;

    return { ...state };
}

export function FraudScreeningOrdersView() {
    const { type } = useParams<{ type?: string }>();
    const isSuspendedOnly = (type === "suspended");

    console.log("FraudScreeningOrdersView render", isSuspendedOnly);

    const defaultClasses = defaultStyles();
    const customClasses = useStyles();

    const dispatch = useDispatch();

    const isFetching = useSelector<AppState, boolean>(
        state => isSuspendedOnly ? state.suspendedOrders.fetching : state.orders.fetching)

    const items = useSelector<AppState, OrderListItem[] | undefined>(
        state => isSuspendedOnly ? state.suspendedOrders.items : state.orders.items, shallowEqual)

    const loadedAll = useSelector<AppState, boolean>(
        state => isSuspendedOnly ? state.suspendedOrders.loadedAll : state.orders.loadedAll)

    const isSuspiciousOnly = useSelector<AppState, boolean>(
        state => isSuspendedOnly
            ? state.suspendedOrders.refine.isSuspiciousOnly
            : state.orders.refine.isSuspiciousOnly);

    const actionName = (name: string) => fmtAction(name, isSuspendedOnly);

    const handleRequestMore = () => {
        if (isFetching || loadedAll) return;
        dispatch({
            type: actionName("ORDERS_LIST"),
            payload: { fetching: true },
            dispatch
        });
    };

    async function handleSetState(order: OrderListItem, state: string, subState: string): Promise<void> {
        if (!items) {
            return;
        }

        const itemIndex = items.findIndex(i => (i.refId === order.refId));

        if (itemIndex < 0) {
            return;
        }

        await apiClient.setOrderState(order.refId, `${state}/${subState}`);

        const newItems = [...items];

        if (subState === "WITHDRAW") {
            const item = items[itemIndex];
            newItems[itemIndex] = item
                .setGenericState("Completed")
                .setSubState("");
        }
        else if (subState === "CANCEL") {
            const item = items[itemIndex];
            newItems[itemIndex] = item
                .setGenericState("Cancelled")
                .setSubState("");
        }
        else if (subState === "FRAUD/SUSPENDED") {
            const item = items[itemIndex].setSubState(subState);
            dispatch({
                type: "SUSPENDED/ORDERS_LIST/ADD_ITEM",
                payload: { item },
                dispatch
            });

            newItems.splice(itemIndex, 1);
        }

        dispatch({
            type: actionName("ORDERS_LIST/REPLACE_ITEMS"),
            payload: { items: newItems },
            dispatch
        });
    }

    const setStateCancelled = async (order: OrderListItem) => {
        const text =
            `Вы действительно хотите ОТМЕНИТЬ вывод?\n` +
            `Стример: ${order.streamerName}\n` +
            `Количество: ${order.amount}\n` +
            `Метод: ${order.methodName}`;
        if (!window.confirm(text)) {
            return;
        }

        await handleSetState(order, "IN_PROGRESS", "CANCEL");
    };

    const setStateSuspended = async (order: OrderListItem) => {
        const text =
            `Вы действительно хотите ЗАМОРОЗИТЬ вывод?\n` +
            `Стример: ${order.streamerName}\n` +
            `Количество: ${order.amount}\n` +
            `Метод: ${order.methodName}`;
        if (!window.confirm(text)) {
            return;
        }

        await handleSetState(order, "IN_PROGRESS", "FRAUD/SUSPENDED");
    };

    const setStateAllowed = async (order: OrderListItem) => {
        const text =
            `Вы действительно хотите РАЗРЕШИТЬ вывод?\n` +
            `Стример: ${order.streamerName}\n` +
            `Количество: ${order.amount}\n` +
            `Метод: ${order.methodName}`;
        if (!window.confirm(text)) {
            return;
        }

        await handleSetState(order, "IN_PROGRESS", "WITHDRAW");
    };

    const handleSuspendedOnlyChange = (event: ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: actionName("ORDERS_LIST/CLEAR"),
            payload: {
                fetching: true,
                loadAll: false,
                refine: {
                    isSuspiciousOnly: event.target.checked,
                    nextPageToken: ""
                }
            },
            dispatch
        });
    };

    useEffect(
        () => {
            if (!items) {
                dispatch({ type: actionName("ORDERS_LIST"), payload: { fetching: true }, dispatch })
            }

            const interval = setInterval(
                () => {
                    if (isLoaderVisible()) {
                        handleRequestMore();
                    }
                },
                1000);

            return function cleanup() {
                clearInterval(interval);
            }
        },
        [ isSuspendedOnly ]);

    let suspiciousFilterCheckbox =
        <Grid item xs={12}>
            <FormGroup>
                <FormControlLabel
                    control={<Checkbox size="small" checked={isSuspiciousOnly} onChange={handleSuspendedOnlyChange} />}
                    label="только подозрительные" />
            </FormGroup>
        </Grid>;

    if (isSuspendedOnly) {
        suspiciousFilterCheckbox = <div/>;
    }

    return <Grid container spacing={3}>
            {isFetching && <CircularProgress className={customClasses.progress} />}
            {suspiciousFilterCheckbox}
            <Grid item xs={12}>
                <TableContainer>
                    <Table className={defaultClasses.table}
                           aria-labelledby="tableTitle"
                           size="medium"
                           aria-label="enhanced table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Дата</TableCell>
                                <TableCell>Имя</TableCell>
                                <TableCell>Тип</TableCell>
                                <TableCell>Метод</TableCell>
                                <TableCell>Состояние</TableCell>
                                <TableCell>Сумма</TableCell>
                                <TableCell>Операции</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(items || []).map((order: OrderListItem) => {
                                const rowClasses = clsx(customClasses.noselect);

                                return (
                                    <TableRow className={rowClasses}>
                                        <TableCell>
                                            {order.createdAt.toLocaleString()}
                                        </TableCell>

                                        <TableCell>
                                            <a href={`/streamers/${order.streamerRefId}`}>
                                                {order.streamerName}
                                            </a>
                                        </TableCell>

                                        <TableCell>{order.typeName}</TableCell>
                                        
                                        <TableCell>{order.methodName}</TableCell>

                                        <TableCell>
                                            {order.stateName}
                                        </TableCell>

                                        <TableCell>
                                            {order.amount}
                                        </TableCell>

                                        <TableCell>
                                            {stateButtons(
                                                order,
                                                setStateAllowed,
                                                setStateCancelled,
                                                setStateSuspended)}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={12}>
                <div id="orders-loader"></div>
            </Grid>
        </Grid>;
}

function fmtAction(action: string, isSuspendedOnly: boolean): string {
    if (isSuspendedOnly) {
        return `SUSPENDED/${action}`;
    }

    return action;
}

function isLoaderVisible(): boolean {
    const el = document.getElementById("orders-loader");
    if (!el) {
        return false;
    }

    const rect = el.getBoundingClientRect();
    const isVisible = (
        (rect.top >= 0) &&
        (rect.left >= 0) &&
        (rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)) &&
        (rect.right <= (window.innerWidth || document.documentElement.clientWidth)));
    if (!isVisible) {
        return false;
    }

    return true;
}
